<template>
    <v-container fluid fill-height :class="{'view--cart-overview':!loading}">
      <v-layout v-if="loading" column align-center pt-5 pb-5>
        <v-progress-circular
          :size="36"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h2 class="title text--secondary text-xs-center mt-3">{{ loading }}</h2>
      </v-layout>
      <v-layout v-if="!loading && cart" column pt-2>
        <template v-if="hasCartItems">
          <h2 class="cart-title__wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><g class="Product" fill="#d2d2d2"><g class="Bttn"><g class="cart-icon"><path class="Fill-5" d="M7.79 2.195L8.094 3.6l4.351.035-1.275 5.042H5.355L2.735 0H.397L.109 1.423h1.685l2.62 8.677h7.667l1.989-7.87z"></path><path d="M5.596 13.551c-.386 0-.7-.363-.7-.81 0-.447.314-.81.7-.81.386 0 .7.363.7.81 0 .447-.314.81-.7.81m0-2.751c-.925 0-1.677.87-1.677 1.94 0 1.07.752 1.941 1.677 1.941s1.677-.87 1.677-1.94c0-1.07-.752-1.941-1.677-1.941" class="Fill-1"></path><path d="M11.05 13.551c-.387 0-.701-.363-.701-.81 0-.447.314-.81.7-.81.387 0 .701.363.701.81 0 .447-.314.81-.7.81m0-2.751c-.925 0-1.678.87-1.678 1.94 0 1.07.753 1.941 1.677 1.941.925 0 1.678-.87 1.678-1.94 0-1.07-.753-1.941-1.678-1.941" class="Fill-3"></path></g></g></g></svg>
            <input class="change-cart-name" ref="cartName" @blur="updateCartName" v-model="cartName" maxlength="40"/>
            <svg @click="$refs['cartName'].focus()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"/></svg>
          </h2>

            <div v-if="cart.lines.lbx.length" class="cart-group">
                <v-cart-item v-for="item in cart.lines.lbx"
                             :key="item.code"
                             :item="item"
                ></v-cart-item>
            </div>
            <div v-if="cart.lines.m_lbx.length" class="cart-group">
                <v-cart-item v-for="item in cart.lines.m_lbx"
                             :key="item.code"
                             :item="item"
                ></v-cart-item>
            </div>

            <div v-if="cart.lines.tax.length" class="cart-group">
                <v-cart-item v-for="item in cart.lines.tax"
                             :key="item.code"
                             :item="item"
                ></v-cart-item>
            </div>

            <div v-if="cart.lines.tbx.length" class="cart-group">
                <v-cart-item v-for="item in cart.lines.tbx"
                             :key="item.code"
                             :item="item"
                ></v-cart-item>
            </div>

            <div v-if="cart.lines.regular.length" class="cart-group">
                <v-cart-item v-for="item in cart.lines.regular"
                             :key="item.code"
                             :item="item"
                ></v-cart-item>
            </div>

            <v-layout v-if="cart.total" wrap class="c-cart--total mb-2">
                <v-flex xs6>
                    <div class="title mb-1">{{ $t('cart.total') }}</div>
                    <div class="caption">{{ $t('cart.excl_tax') }}</div>
                </v-flex>
                <v-flex xs6 pt-2>
                    <h2 class="text-xs-right">{{ cart.total | currency }}</h2>
                </v-flex>
            </v-layout>

            <v-btn v-if="cart.can_order" color="secondary" :disabled="!isSubmitButtonEnabled" depressed large @click="navigateToConfirm">{{ $t('cart.order') }}</v-btn>
        </template>
        <template v-else>
            <div class="text-xs-center pt-2 pb-4">
                <v-icon large color="grey">fa-cart-plus</v-icon>
            </div>
            <p class="text-xs-center">{{ $t('cart.no_results') }}</p>
            <v-btn color="secondary" depressed large :to="{ name: 'home' }">{{ $t('cart.add_products') }}</v-btn>
        </template>
      </v-layout>
    </v-container>
</template>

<script>
import VCartItem from '@/components/CartItem.vue';
import i18n from '@/i18n';

export default {
  components: {
    VCartItem,
  },
  created() {
    this.init();
  },
  data() {
    return {
      loading: null,
      cartName: '',
    };
  },
  computed: {
    isSubmitButtonEnabled() {
      return this.cart.name === this.cartName;
    },

    // Computed to get formatted cart.
    cart() {
      const { mercureCart } = this.$store.state;
      const originalCart = this.$store.state.cart;

      // Update cart name.
      if (mercureCart?.name && mercureCart.name !== '') {
        originalCart.name = mercureCart.name;
      }

      if (mercureCart && mercureCart.cart_id === originalCart.id) {
        mercureCart.lines.forEach((line) => {
          originalCart.lines.regular.forEach((fitting) => {
            if (fitting.id === line.id) {
              const updatedFitting = fitting;

              updatedFitting.unitPrice = Number(line.unit_price_raw);
              if (line.magnitude > 1) {
                updatedFitting.unitPrice /= Number(line.magnitude);
              }

              updatedFitting.totalPrice = line.total_price_raw;
              updatedFitting.imageData = {
                large: line.images.teaser_retina,
                small: line.images.thumbnail,
              };
              updatedFitting.name = line.name;
              updatedFitting.description = line.descriptions[i18n.locale];
              updatedFitting.quantity = line.quantity;
            }
          });

          const configurations = ['lbx', 'tax', 'tbx'];

          configurations.forEach((configKey) => {
            originalCart.lines[configKey].forEach((configuration) => {
              if (configuration.id === line.id) {
                const updatedConfiguration = configuration;
                updatedConfiguration.unitPrice = line.unit_price_raw;
                updatedConfiguration.totalPrice = line.total_price_raw;
                updatedConfiguration.quantity = line.quantity;
              }
            });
          });
        });
        originalCart.total = this.$store.state.mercureCart.total_raw;
      }

      return originalCart;
    },
    // Computed to return cart id. Used for a watcher to re-init mercure.
    cartId() {
      if (this.cart && this.cart.id) {
        return this.cart.id;
      }
      return null;
    },
    hasCartItems() {
      let hasItems = false;
      if (this.cart && this.cart.lines && (this.cart.lines.lbx.length || this.cart.lines.tbx.length || this.cart.lines.regular.length || this.cart.lines.tax.length)) {
        hasItems = true;
      }
      return hasItems;
    },
  },
  methods: {
    // Load the shopping cart.
    init() {
      this.loading = this.$t('cart.prepare_cart_loading');
      this.$store.dispatch('getCart').then(() => {
        this.cartName = this.cart.name;
        this.$store.dispatch('mercureCart');
        this.loading = null;
      });
    },

    // Only navigate when submit button is enabled.
    navigateToConfirm() {
      if (!this.isSubmitButtonEnabled) {
        return;
      }

      this.$router.push({ name: 'cart-confirmation' });
    },

    // Update cart name through endpoint.
    async updateCartName() {
      // Exit early if the name did not change.
      if (this.cartName === this.cart.name) {
        return;
      }

      // Update the name.
      const output = await this.$store.dispatch('renameCart', {
        name: this.cartName,
        cartId: this.cart.id,
      });

      // If name save failed, reset to previous name.
      if (!output) {
        this.cartName = this.cart.name;
      }
    },
  },
  watch: {
    // Fully reload the cart when new line items are added. This prevents a weird glitch that
    // would otherwise partially show the no items icon.
    hasCartItems(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.init();
      }
    },
    // Re-init mercure listener when cart id changes.
    cartId() {
      this.init();
    },
  },
};
</script>

<style lang="scss">
    .view--cart-overview {
        background: #fff;
    }
    .cart-title--ref {
        font-size: 14px;
        font-weight: 500;
        color: #999;
    }
    .c-cart--total {
        padding: 15px;
        border-top: 1px solid hsla(0,0%,79%,.5);
    }
    .cart-group--title {
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
    }

    .cart-title__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      column-gap:10px;
      padding-right: 10px;

      svg {
        flex: 0 0 20px;
      }

      .change-cart-name {
        flex: 1 1 auto;
      }
    }
</style>
